/* 
====== 
Variables 
======
*/
:root {
  --primaryLightColor: #d4e6a5;
  --primaryLightColor: #65a30d;

  --primaryColor: #476a2e;
  --primaryColor: #4d7c0f;
  --primaryDarkColor: #c02c03;
  --mainWhite: #fff;
  --offWhite: #f7f7f7;
  --mainBackground: #f1f5f8;
  --mainOverlay: rgba(35, 10, 36, 0.4);
  --mainBlack: #222;
  --mainGrey: #ececec;
  --darkGrey: #afafaf;
  --darkerGrey: #a858585;
  --mainRed: #bd0303;
  --mainRed: #990012;
  --mainTransition: all 0.3s linear;
  --mainSpacing: 0.3rem;
  --mainSpacing: 0.25rem;
  --lightShadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
  --darkShadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
  --mainBorderRadius: 0.25rem;
  --smallWidth: 85vw;
  --smallerWidth: 90vw;
  --smallestWidth: 95vw;
  --maxWidth: 40rem;
  --fullWidth: 1170px;
}
/* 
====== 
Global Styles 
======
*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-family: "Roboto", sans-serif;
  color: var(--mainBlack);
  background: var(--mainBackground);
  background: var(--mainWhite);
  line-height: 1.4;
  font-size: 1rem;
  font-weight: 400;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--slantedFont);
  margin-bottom: 1.25rem;
  letter-spacing: var(--mainSpacing);
}
p {
  margin-bottom: 1.25rem;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
  color: var(--mainBlack);
}
img {
  width: 100%;
  display: block;
}

/* 
====== 
Buttons 
======
*/
.btn,
.btn-white,
.btn-primary {
  text-transform: uppercase;
  letter-spacing: var(--mainSpacing);
  color: var(--primaryColor);
  border: 2px solid var(--primaryColor);
  padding: 0.45rem 0.8rem;
  display: inline-block;
  transition: var(--mainTransition);
  cursor: pointer;
  font-size: 0.8rem;
  background: transparent;
  border-radius: var(--mainBorderRadius);
  display: inline-block;
}
.btn:hover {
  background: var(--primaryColor);
  color: var(--mainWhite);
}
.btn-white {
  background: transparent;
  color: var(--mainWhite);
  border-color: var(--mainWhite);
}
.btn-white:hover {
  background: var(--mainWhite);
  color: var(--primaryColor);
}
.btn-primary {
  background: var(--primaryColor);
  color: var(--mainWhite);
  border-color: transparent;
}
.btn-primary:hover {
  background: var(--primaryLightColor);
  color: var(--primaryColor);
  color: var(--mainWhite);
}
.btn-block {
  width: 100%;
  display: block;
  margin: 0 auto;
  box-shadow: var(--lightShadow);
  text-align: center;
}
.btn-details {
  padding: 0.65rem 0;
  display: block;
  text-transform: uppercase;
  text-align: center;
  background: none;
  font-weight: 400;
  border-color: var(--primaryColor);
  border-color: var(--mainBlack);
  border-width: 1.55px;
  color: var(--primaryColor);
  color: var(--mainBlack);
}
.btn-details:hover {
  background: var(--primaryLightColor);
  background: var(--primaryColor);
  border-color: var(--primaryLightColor);
  color: var(--mainWhite);
}

.btn-bck {
  margin-top: 2rem;
}
/* 
====== 
Navbar
======
*/
.navbar {
  background: #4d7c0f;
  background: var(--mainWhite);
  height: 5rem;
  display: flex;
  align-items: center;
  /* border-bottom: 2px solid var(--primaryColor); */
  /* box-shadow: var(--lightShadow); */
}
.nav-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: var(--smallWidth);
  margin: 0 auto;
  max-width: var(--fullWidth);
}
.nav-links {
  display: flex;
  align-items: center;
}
.nav-links a {
  /* color: var(--mainWhite);
  color: var(--primaryColor);
  color: var(--darkGrey); */
  text-transform: capitalize;
  display: inline-block;
  margin-right: 0.3rem;
  font-weight: 400;
  letter-spacing: 2px;
  letter-spacing: 1px;
  font-size: 1rem;
  padding: 0.25rem 0.5rem;
  transition: var(--mainTransition);
}
/* .nav-links a:hover {
  color: var(--primaryColor);
} */
.logo {
  /* width: 12rem; */
  margin-bottom: 0;
  color: green;
  color: white;
  color: var(--primaryColor);
  letter-spacing: 1px;
  font-size: 2rem;
  font-weight: 700;
}
/* .insta {
  color: red;
} */
/* 
====== 
About
======
*/
.about-section {
  width: var(--smallWidth);
  max-width: var(--maxWidth);
  margin: 0 auto;
}
.about-section p {
  line-height: 2rem;
  font-weight: 400;
  letter-spacing: 2px;
}
/* 
====== 
Error
======
*/
.error-page {
  display: flex;
  justify-content: center;
}
.error-container {
  text-align: center;
  text-transform: capitalize;
}
/* 
====== 
Cocktails List 
======
*/

.section {
  padding: 5rem 0;
}
.section-title {
  font-size: 2rem;
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
  text-align: center;
  margin-bottom: 3.5rem;
  margin-top: 1rem;
}
.cocktails-center {
  width: var(--smallWidth);
  /* MODIFICATION  */
  width: var(--smallerWidth);
  margin: 0 auto;
  max-width: var(--fullWidth);
  display: grid;
  row-gap: 1.5rem;
  column-gap: 1.25rem;
  /* align-items: start; */
  /* MODIFICATION */
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
}

.no-recipes {
  text-transform: none;
  letter-spacing: 1.75px;
  font-size: 1rem;
  margin: 2rem 1.5rem 0rem 1.5rem;
  font-weight: 400;
}
.no-recipes-cont {
  margin: 0.75rem 1.5rem;
}

@media screen and (min-width: 992px) {
  .no-recipes {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 576px) {
  .cocktails-center {
    grid-template-columns: repeat(auto-fill, minmax(338.8px, 1fr));
  }
}
/* 
====== 
Cocktail 
======
*/

.cocktail {
  background: var(--mainWhite);
  margin-bottom: 2rem;
  box-shadow: var(--lightShadow);
  transition: var(--mainTransition);
  display: grid;
  grid-template-rows: auto 1fr;
  border-radius: var(--mainBorderRadius);
}
.cocktail:hover {
  box-shadow: var(--darkShadow);
}
.cocktail img {
  height: 20rem;
  /* MODIFICATION */
  height: 224px;
  height: auto;
  /* MODIFICATION */
  /* width: 168px; */
  object-fit: cover;
  /* border-top-left-radius: var(--mainBorderRadius);
  border-top-right-radius: var(--mainBorderRadius); */
}
.cocktail-footer {
  padding: 1.5rem;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
}

/* .cocktail-footer h3 {
  font-size: 2rem;
} */
/* .cocktail-footer p {
  color: var(--darkGrey);
  margin-bottom: 0.5rem;
} */

.recipe-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vege-type {
  color: var(--mainRed);
  color: var(--darkGrey);
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 1px;
}
.recipe-time {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  margin-bottom: 0.5rem;
}
.time {
  font-size: 0.75rem;
  margin-left: 0.3rem;
  font-weight: 500;
  text-transform: uppercase;
  color: #ac8f63;
  color: #b11226;
  color: #990012;
}
.recipe-title {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.2;
  transition: all 0.3s ease-in-out;
  letter-spacing: normal;
  margin-bottom: 0.75rem;
}
/* 
====== 
Form
======
*/
/* .search {
  margin-top: 1rem;
  padding-bottom: 0;
} */

.search-form {
  width: var(--smallWidth);
  margin: 0 auto;
  max-width: var(--maxWidth);
  /* background: var(--mainWhite); */
  padding: 0 2.5rem;
  text-transform: none;
  border-radius: var(--mainBorderRadius);
  /* box-shadow: var(--lightShadow); */
}

.form-control label {
  display: block;
  text-align: center;
  margin-bottom: 0.75rem;
  font-weight: bold;
  letter-spacing: 0.15rem;
  color: var(--primaryColor);
  color: var(--mainWhite);
  color: var(--primaryLightColor);
}
.form-control input {
  width: 100%;
  border: none;
  border-color: transparent;
  background: var(--mainBackground);
  background: var(--mainWhite);
  border-radius: var(--mainBorderRadius);
  padding: 0.5rem;
  font-size: 1.2rem;
}
/* 
====== 
Cocktail
======
*/

.cocktail-section {
  text-align: center;
  padding: 3rem 0;
}
.drink {
  width: var(--smallWidth);
  max-width: var(--fullWidth);
  margin: 0 auto;
  text-align: left;
}

.single-dish-title {
  font-size: 2rem;
  font-weight: 400;
  line-height: 2rem;
  margin-bottom: 1.5rem;
}
.drink figure {
  overflow: hidden;
}
.drink img {
  /* border-radius: var(--mainBorderRadius); */
  transition: all 0.4s;
}

.drink img:hover {
  transform: scale(1.1);
}
.drink p {
  font-weight: bold;
  text-transform: capitalize;
  line-height: 1.8;
}
.drink span {
  margin-right: 0.5rem;
}
.drink-data {
  background: var(--primaryLightColor);
  padding: 0.25rem 0.5rem;
  border-radius: var(--mainBorderRadius);
  color: var(--primaryColor);
}
.drink-info {
  padding-top: 2rem;
}

.drink figure {
  margin-bottom: 3rem;
}

.ingredients-list {
  list-style-type: disc;
  margin-left: 1.25rem;
}
.single-dish-h2 {
  letter-spacing: normal;
  font-size: 1.5rem;
  font-style: italic;
  line-height: 1.25rem;
  font-weight: 400;
  /* margin-top: 1.25rem; */
}

.ingredients-li,
.instructions-li {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 0.75rem;
}
.instructions-li {
  margin-top: 0.5rem;
}

.instructions-li span {
  margin-right: 4px;
  font-weight: 500;
}

@media screen and (min-width: 992px) {
  .drink {
    display: grid;
    grid-template-columns: 2fr 3fr;
    gap: 3rem;
    /* align-items: center; */
  }
  .br {
    display: none;
  }
  .ingredients-box {
    order: 3;
  }
  .instructions-box {
    order: 2;
  }
  .drink figure {
    margin-bottom: 0;
  }
  .single-dish-title {
    font-size: 2.5rem;
    margin-bottom: 3.5rem;
  }
  .nav-links a {
    font-size: 1.5rem;
    color: var(--darkerGrey);
  }
  .drink-info {
    padding-top: 0;
  }
}

/* 
====== 
Tips
======
*/
.tips h3,
.h3-tips,
.tips p,
.tips li {
  text-transform: none;
  letter-spacing: 1.5px;
}
.tips .list-subheading {
  margin-bottom: 0;
  font-weight: 500;
}
.h1-tips {
  text-transform: none;
}
.h3-tips {
  letter-spacing: normal;
  font-weight: 500;
}
.tips ul {
  list-style-type: square;
  margin-left: 2rem;
}
.heading {
  list-style-type: none;
  margin-left: 0;
}
.loader,
.loader:before,
.loader:after {
  background: transparent;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: var(--primaryColor);
  text-indent: -9999em;
  margin: 88px auto;
  margin-top: 20rem;
  position: relative;
  font-size: 3rem;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: "";
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
.category-container {
  max-width: var(--smallWidth);
  margin: 0 auto;
  /* background: red; */
}
.choose-a-category {
  text-align: center;
  margin-top: 1.75rem;
  margin-bottom: 0;
  font-weight: 400;
}
.btn-container {
  /* MODIFICATION */
  margin-top: 0.75rem;
  margin-bottom: 4rem;
  /* MODIFICATION */
  margin-bottom: 0;
  margin: 0.75rem auto 0 auto;
  /* display: flex;
  justify-content: center; */
  /* GRID */
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
  gap: 0.75rem 0.75rem;

  max-width: var(--smallWidth);
  /* background: yellow; */
}

@media screen and (min-width: 992px) {
  .btn-container {
    grid-template-columns: repeat(9, 1fr);
  }
}
.filter-btn {
  background: transparent;
  background: var(--primaryColor);
  border-color: transparent;
  font-size: 1rem;
  font-size: 0.8rem;
  text-transform: capitalize;
  margin: 0 0.5rem;
  letter-spacing: 1px;
  padding: 0.375rem 0.75rem;
  color: var(--primaryColor);
  color: var(--mainWhite);
  cursor: pointer;
  transition: var(--mainTransition);
  border-radius: var(--mainBorderRadius);
  /* GRID */
  display: inline;
}

@media screen and (min-width: 992px) {
  .filter-btn {
    font-size: 1rem;
  }
}
.filter-btn:hover {
  background: var(--primaryLightColor);
  color: var(--offWhite);
}

.header {
  min-height: 25vh;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("../public/header-bck.jpg") center/cover no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-text {
  /* z-index: 2; */
  color: var(--mainWhite);
}

@media screen and (min-width: 992px) {
  .header {
    min-height: 35vh;
  }
}
